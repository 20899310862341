import './App.css';
import logo from './moveouttr.png'; // Import the new logo image
import phoneImage1 from './seperator.png'; // Image of your app on a phone
import phoneImage2 from './sevkiyat.png'; // Another image of your app on a phone
import phoneImage3 from './assets/gonder.png'; // Another image of your app on a phone
import phoneImage4 from './assets/create.png'; // Another image of your app on a phone
import phoneImage5 from './assets/mape.png'; // Another image of your app on a phone
import { useState, useEffect, useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import React, { lazy, Suspense } from 'react';
import aboutImage from './assets/post.png';  // Add this import
import Login from './components/Login';
import PreRegister from './components/PreRegister';  // Add this import
import { Analytics } from "@vercel/analytics/react"
import footerVideo from './assets/movloop.mp4';  // Replace the GIF import
import ad2Image from './assets/ad2.png';  // Add this import at the top with other imports
import { HelmetProvider } from 'react-helmet-async';
import SEO from './components/SEO';

// Custom hook for typing effect
const useTypingEffect = (messages, speed) => {
  const [displayedText, setDisplayedText] = useState('');
  const [messageIndex, setMessageIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let timer;
    const currentMessage = messages[messageIndex];

    if (isDeleting) {
      timer = setTimeout(() => {
        setDisplayedText(text => text.slice(0, -1));
        if (displayedText === '') {
          setIsDeleting(false);
          setMessageIndex((prev) => (prev + 1) % messages.length);
        }
      }, speed / 2);
    } else {
      if (displayedText === currentMessage) {
        // Wait before starting to delete
        timer = setTimeout(() => {
          setIsDeleting(true);
        }, 2000);
      } else {
        timer = setTimeout(() => {
          setDisplayedText(currentMessage.slice(0, displayedText.length + 1));
        }, speed);
      }
    }

    return () => clearTimeout(timer);
  }, [messages, displayedText, messageIndex, isDeleting, speed]);

  return displayedText;
};

// Add lazy loading for route-based code splitting
const FAQ = lazy(() => import('./components/FAQ'));
const Newsletter = lazy(() => import('./components/Newsletter'));


// Add Intersection Observer for better performance
const useLazyLoad = (options = {}) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return [elementRef, isVisible];
};

// Add custom hook for language management
const useLanguage = () => {
  const [currentLang, setCurrentLang] = useState(() => {
    // Get initial language from localStorage or browser preference
    return localStorage.getItem('preferred_language') || 
           navigator.language.split('-')[0] || 
           'tr';
  });

  useEffect(() => {
    localStorage.setItem('preferred_language', currentLang);
    document.documentElement.lang = currentLang;
  }, [currentLang]);

  return [currentLang, setCurrentLang];
};

// Add analytics tracking
const useAnalytics = () => {
  const trackEvent = useCallback((eventName, data) => {
    // Implement your analytics logic here
    console.log('Analytics:', eventName, data);
  }, []);

  return { trackEvent };
};

// Modify the useCountAnimation hook to accept multiple triggers
const useCountAnimation = (end, duration = 2000, triggerRefs) => {
  const [count, setCount] = useState(0);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  useEffect(() => {
    let startTime;
    let animationFrame;

    const animate = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const progress = (currentTime - startTime) / duration;

      if (progress < 1) {
        setCount(Math.floor(end * progress));
        animationFrame = requestAnimationFrame(animate);
      } else {
        setCount(end);
        setHasAnimated(true);
      }
    };

    // Check if any of the trigger sections are in view
    const shouldAnimate = 
      inView || 
      triggerRefs.some(triggerRef => triggerRef?.current?.inView);

    if (shouldAnimate && !hasAnimated) {
      animationFrame = requestAnimationFrame(animate);
    }

    return () => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [end, duration, inView, triggerRefs, hasAnimated]);

  return [ref, count];
};

// Add this helper function after useCountAnimation
const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M+';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K+';
  }
  return num + '+';
};

// Update the useActiveSection hook to include a clear function
const useActiveSection = () => {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    // Add scroll handler to clear active section when near top
    const handleScroll = () => {
      if (window.scrollY < 100) {  // Adjust this value as needed
        setActiveSection('');
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.querySelectorAll('section[id]').forEach((section) => {
      observer.observe(section);
    });

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [activeSection, setActiveSection];
};

function App() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentLang, setCurrentLang] = useLanguage();
  const phoneImages = [phoneImage1, phoneImage2, phoneImage3, phoneImage4, phoneImage5]; // Only include the first two images
  const messages = {
    tr: [
      "Sevkiyatlarınızı kolayca gerçekleştirin!",
      "Dağıtım ağınızı oluşturun!",
      "Aracınızı paylaşın sevkiyatları değerlendirin!",
      "Nakliye operasyonlarınızı optimize edin!",
      "İade ve değişiklikleri kolayca yönetin!",
      "Maliyetlerinizi optimize edin!",
      "Doğayı koruyun!"
    ],
    en: [
      "Easily manage your shipments!",
      "Create your distribution network!",
      "Share your vehicle and evaluate shipments!",
      "Optimize your shipping operations!",
      "Manage returns and changes easily!",
      "Optimize your costs!",
      "Protect the environment!"
    ]
  };
  const typingSpeed = 100; // Speed of typing effect
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for hamburger menu visibility
  const [heroRef, heroInView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  const nextImage = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % phoneImages.length);
  }, [phoneImages.length]);

  // Automatically change the image every 5 seconds
  useEffect(() => {
    const interval = setInterval(nextImage, 5000); // Change image every 5 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [nextImage]); // Include nextImage in the dependency array

  // Get the current message
  const currentMessage = messages[currentLang][0]; // Always use the first message
  const displayedMessage = useTypingEffect(messages[currentLang], typingSpeed);

  // Function to toggle hamburger menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Update the scroll effect in App.js
  const [showLogin, setShowLogin] = useState(false);
  const [showPreRegister, setShowPreRegister] = useState(false); // Add this state
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('.App-header');
      if (header) {  // Only modify classList if header exists
        if (window.scrollY > 20) {
          header.classList.add('header-scrolled');
        } else {
          header.classList.remove('header-scrolled');
        }
      }
    };

    // Only add scroll listener if we're not showing login
    if (!showLogin) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [showLogin]); // Add showLogin to dependency array

  // Add language detection on mount
  const { trackEvent } = useAnalytics();
  useEffect(() => {
    // Track language changes
    trackEvent('language_changed', { lang: currentLang });
  }, [currentLang]);

  // Update the translations object in App.js
  const translations = {
    tr: {
      nav: {
        about: "Hakkımızda",
        features: "Özellikler",
        testimonials: "Vizyonumuz",
        faq: "SSS",
        download: "İndir"
      },
      features: {
        title: "Özellikler",
        items: [
          {
            number: "01",
            title: "Optimize Edilmiş Rotalar 🛣️",
            description: "Boş seferleri azaltarak ve talebe göre aynı yöndeki sevkiyatları buluşturarak araçların kapasitelerini daha verimli kullanmayı ve yakıt tüketimini azaltmayı sağlıyoruz."
          },
          {
            number: "02",
            title: "Ekonomik Taşımacılık 📊",
            description: "Kullanıcılar ve sürücüler arasında doğrudan bağlantı sağlayarak daha uygun fiyatlarla taşımacılık hizmeti sunuyoruz."
          },
          {
            number: "03",
            title: "Sürdürülebilir Lojistik ♻️",
            description: "Karbon nötr hedefimiz doğrultusunda, çevre dostu uygulamalar ve elektrikli araç teşvikleri ile iklim dostu bir taşımacılık modeli oluşturuyoruz."
          },
          {
            number: "04",
            title: "Geniş Kapsayıcılık 🌐",
            description: "Hem bireysel kullanıcılar hem de küçük/orta ölçekli işletmeler için erişilebilir çözümler sunarak ekonomik fırsatlar yaratıyoruz."
          },
          {
            number: "05",
            title: "İstihdam Fırsatları 💼",
            description: "Bağımsız sürücüler ve taşıma şirketleri için yeni iş fırsatları yaratıyor, ekonomik büyümeye katkı sağlıyoruz."
          }
        ]
      },
      stats: {
        users: "Aktif Kullanıcı",
        shipments: "Tamamlanan Sevkiyat",
        cities: "Şehir"
      },
      cta: {
        title: "Hemen Başlayın",
        subtitle: "Sevkiyatlarınızı moveout.app ile yönetin",
        tryFree: "Ücretsiz Deneyin",
        contact: "İletişime Geçin"
      },
      testimonials: {
        title: "Vizyonumuz",
        items: [
          {
            text: "moveout.app tamamen değiştirdi nakliye operasyonlarımızı. Artık tüm süreçlerimiz çok daha verimli.",
            name: "Ahmet Yılmaz",
            role: "Lojistik Müdürü"
          },
          {
            text: "Maliyetlerimizi %30 düşürdük. Müşteri memnuniyetimiz arttı.",
            name: "Mehmet Kaya",
            role: "Operasyon Direktörü"
          },
          {
            text: "Kullanımı çok kolay ve müşteri desteği mükemmel.",
            name: "Zeynep Arslan",
            role: "Tedarik Zinciri Uzmanı"
          }
        ]
      },
      faq: {
        title: "Sıkça Sorulan Sorular",
        items: [
          {
            question: "moveout.app nasıl çalışır?",
            answer: "Detaylı açıklama..."
          }
        ]
      },
      newsletter: {
        title: "Güncel Kalın",
        subtitle: "Yeni özellikler ve güncellemelerden haberdar olun",
        placeholder: "Email adresiniz",
        button: "Abone Ol",
        successMessage: "Başarıyla abone oldunuz!",
        error: "Bir hata oluştu. Lütfen tekrar deneyin."
      },
      about: {
        title: "Hakkımızda",
        description: "moveout.app, nakliye ve lojistik süreçlerinizi dijitalleştirerek verimliliğinizi artırmanıza yardımcı olan yenilikçi bir platformdur."
      },
      vision: {
        items: [
          {
            icon: "🌱",
            title: "Kaynak Verimliliği",
            description: "Taşıma hizmetlerini optimize ederek araçların daha verimli kullanılmasını sağlar. Boş gidişlerin azaltılması, hem çevresel etkileri azaltır hem de sürücülere ek gelir fırsatları sunar."
          },
          {
            icon: "🌍",
            title: "Karbon Ayak İzi Azaltma",
            description: "Taşımacılık süreçlerini optimize ederek, araçların daha az yakıt tüketmesini sağlamak, karbon salınımını azaltır. Bu, çevresel sürdürülebilirliği destekler ve iklim değişikliğiyle mücadeleye katkıda bulunur."
          },
          {
            icon: "💡",
            title: "Sürdürülebilir Lojistik Anlayışı",
            description: "Çevre dostu taşımacılık çözümleri ve sürdürülebilir lojistik uygulamaları ile sektörde yeni bir anlayış oluşturmayı hedefliyoruz. Karbon ayak izini azaltan ve doğal kaynakları koruyan bir yaklaşım benimsiyoruz."
          }
        ],
        footer: "Ekonomik, sosyal ve çevresel açıdan önemli sorunları çözerek, hem kullanıcılar hem de topluluklar için ölçülebilir pozitif etkiler yaratmayı hedeflemekteyiz."
      },
      footer: {
        company: "Şirket",
        contact: "İletişim",
        social: "Sosyal Medya",
        rights: "© 2023-2025 moveout.app tüm hakları saklıdır.",
        privacy: "Gizlilik Politikası",
        terms: "Kullanım Koşulları"
      }
    },
    en: {
      nav: {
        about: "About",
        features: "Features",
        testimonials: "Vision",  // Changed from "Testimonials" to match the Turkish version
        faq: "FAQ",
        download: "Download"
      },
      features: {
        title: "Features",
        items: [
          {
            number: "01",
            title: "Optimized Routes 🛣️",
            description: "We reduce fuel consumption and increase vehicle capacity utilization by minimizing empty trips and matching shipments going in the same direction based on demand."
          },
          {
            number: "02",
            title: "Economic Transportation 📊",
            description: "We provide more affordable transportation services by establishing direct connections between users and drivers."
          },
          {
            number: "03",
            title: "Sustainable Logistics ♻️",
            description: "In line with our carbon neutral goal, we create a climate-friendly transportation model with environmentally friendly practices and electric vehicle incentives."
          },
          {
            number: "04",
            title: "Wide Inclusivity 🌐",
            description: "We create economic opportunities by offering accessible solutions for both individual users and small/medium-sized businesses."
          },
          {
            number: "05",
            title: "Employment Opportunities 💼",
            description: "We create new job opportunities for independent drivers and transportation companies, contributing to economic growth."
          }
        ]
      },
      stats: {
        users: "Active Users",
        shipments: "Completed Shipments",
        cities: "Cities"
      },
      cta: {
        title: "Get Started Now",
        subtitle: "Manage your shipments with moveout.app",
        tryFree: "Try for Free",
        contact: "Contact Us"
      },
      testimonials: {
        title: "Our Vision",  // Changed to match the Vision section
        items: [
          {
            text: "By optimizing transportation services, we ensure more efficient use of vehicles. Reducing empty trips both reduces environmental impact and provides additional income opportunities for drivers.",
            name: "Resource Efficiency",
            role: "Environmental Impact"
          },
          {
            text: "By optimizing transportation processes, we reduce fuel consumption and carbon emissions. This supports environmental sustainability and contributes to the fight against climate change.",
            name: "Carbon Footprint Reduction",
            role: "Climate Action"
          },
          {
            text: "We aim to create a new understanding in the sector with eco-friendly transportation solutions and sustainable logistics practices. We adopt an approach that reduces carbon footprint and protects natural resources.",
            name: "Sustainable Logistics",
            role: "Future Focus"
          }
        ]
      },
      faq: {
        title: "Frequently Asked Questions",
        items: [
          {
            question: "How does moveout.app work?",
            answer: "Detailed explanation..."
          }
        ]
      },
      newsletter: {
        title: "Stay Updated",
        subtitle: "Get notified about new features and updates",
        placeholder: "Your email address",
        button: "Subscribe",
        successMessage: "Successfully subscribed!",
        error: "An error occurred. Please try again."
      },
      about: {
        title: "About Us",
        description: "moveout.app is an innovative platform that helps increase your efficiency by digitalizing your shipping and logistics processes."
      },
      vision: {
        items: [
          {
            icon: "🌱",
            title: "Resource Efficiency",
            description: "We optimize transportation services to ensure more efficient use of vehicles. Reducing empty trips both minimizes environmental impact and provides additional income opportunities for drivers."
          },
          {
            icon: "🌍",
            title: "Carbon Footprint Reduction",
            description: "By optimizing transportation processes and reducing fuel consumption, we decrease carbon emissions. This supports environmental sustainability and contributes to the fight against climate change."
          },
          {
            icon: "💡",
            title: "Sustainable Logistics Approach",
            description: "We aim to create a new understanding in the sector with eco-friendly transportation solutions and sustainable logistics practices. We adopt an approach that reduces carbon footprint and protects natural resources."
          }
        ],
        footer: "By solving significant economic, social, and environmental challenges, we aim to create measurable positive impacts for both users and communities."
      },
      footer: {
        company: "Company",
        contact: "Contact",
        social: "Social Media",
        rights: "© 2023-2025 moveout.app all rights reserved.",
        privacy: "Privacy Policy",
        terms: "Terms of Use"
      }
    }
  };

  // Use translations in your JSX
  const t = translations[currentLang];

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNewsletterSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Implement newsletter submission logic here
  };

  const handleChange = (e) => {
    // Implement change handling logic here
  };

  // Add loading state for feature images
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const handleImageLoad = () => {
    setImagesLoaded(true);
  };

  // Update the scrollToSection function
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerHeight = document.querySelector('.App-header').offsetHeight;
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      
      // Close mobile menu if open
      setIsMenuOpen(false);
    }
  };

  // Add refs for both about and CTA sections
  const [aboutRef, aboutInView] = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  const [ctaRef, ctaInView] = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  // Create ref objects to pass to the animations
  const aboutTriggerRef = useRef({ inView: false });
  const ctaTriggerRef = useRef({ inView: false });

  // Update both trigger refs when sections come into view
  useEffect(() => {
    aboutTriggerRef.current = { inView: aboutInView };
  }, [aboutInView]);

  useEffect(() => {
    ctaTriggerRef.current = { inView: ctaInView };
  }, [ctaInView]);

  // Update the statistics refs to use both triggers
  const triggerRefs = [aboutTriggerRef, ctaTriggerRef];
  const [usersRef, userCount] = useCountAnimation(250, 2000, triggerRefs);
  const [shipmentsRef, shipmentCount] = useCountAnimation(1200, 2000, triggerRefs);
  const [citiesRef, cityCount] = useCountAnimation(8, 2000, triggerRefs);

  const [activeSection, setActiveSection] = useActiveSection();

  // Update the scrollToTop function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setActiveSection('');  // Clear the active section
  };

  return (
    <HelmetProvider>
      <div className="App">
        <SEO 
          title="Smart Logistics Solutions"
          description="Moveout - Revolutionizing logistics with smart solutions. Manage shipments, optimize costs, and contribute to a sustainable future with our innovative platform."
          keywords="logistics, shipping, transportation, sustainable logistics, smart shipping, delivery management"
          url="/"
          lang={currentLang}
        />
        {showLogin ? (
          <Login 
            currentLang={currentLang} 
            onClose={() => {
              document.body.style.overflow = ''; // Re-enable scrolling
              setShowLogin(false);
            }} 
          />
        ) : showPreRegister ? (
          <PreRegister
            currentLang={currentLang}
            onClose={() => {
              document.body.style.overflow = '';
              setShowPreRegister(false);
            }}
          />
        ) : (
          <>
            <header className="App-header">
              <div className="Header-content">
                <img 
                  src={logo} 
                  alt="Logo" 
                  className="App-logo" 
                  onClick={scrollToTop}
                  style={{ cursor: 'pointer' }}
                />
                <nav className="Header-nav">
                  <ul>
                    <li>
                      <a 
                        href="#about"
                        onClick={(e) => {
                          e.preventDefault();
                          scrollToSection('about');
                        }}
                        className={activeSection === 'about' ? 'active' : ''}
                      >
                        {t.nav.about}
                      </a>
                    </li>
                    <li>
                      <a 
                        href="#features"
                        onClick={(e) => {
                          e.preventDefault();
                          scrollToSection('features');
                        }}
                        className={activeSection === 'features' ? 'active' : ''}
                      >
                        {t.nav.features}
                      </a>
                    </li>
                    <li>
                      <a 
                        href="#testimonials"
                        onClick={(e) => {
                          e.preventDefault();
                          scrollToSection('testimonials');
                        }}
                        className={activeSection === 'testimonials' ? 'active' : ''}
                      >
                        {t.nav.testimonials}
                      </a>
                    </li>
                    <li>
                      <a 
                        href="#faq"
                        onClick={(e) => {
                          e.preventDefault();
                          scrollToSection('faq');
                        }}
                        className={activeSection === 'faq' ? 'active' : ''}
                      >
                        {t.nav.faq}
                      </a>
                    </li>
                    <li>
                      <select 
                        className="lang-select"
                        value={currentLang}
                        onChange={(e) => setCurrentLang(e.target.value)}
                      >
                        <option value="tr">🇹🇷 TR</option>
                        <option value="en">🇬🇧 EN</option>
                      </select>
                    </li>
                    <li>
                      <button 
                        className="primary-btn" 
                        onClick={() => {
                          document.body.style.overflow = 'hidden';
                          setShowLogin(true);
                        }}
                      >
                        {currentLang === 'tr' ? 'Giriş Yap' : 'Login'}
                      </button>
                    </li>
                    <li>
                      <button 
                        className="secondary-btn" 
                        onClick={() => {
                          document.body.style.overflow = 'hidden';
                          setShowPreRegister(true);
                        }}
                      >
                        {currentLang === 'tr' ? 'Ön Kayıt' : 'Pre-Register'}
                      </button>
                    </li>
                  </ul>
                </nav>
                <button className="hamburger-menu" onClick={toggleMenu}>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </header>

            <main className="App-body">
              <section 
                ref={heroRef}
                className={`hero ${heroInView ? 'visible' : ''}`}
              >
                <div className="hero-text">
                  <h1>moveout.app</h1>
                  <p className="Typing-message">{displayedMessage}</p>
                  <div className="app-store-buttons">
                    <button className="store-btn">
                      <span className="store-icon">
                        <svg viewBox="0 0 24 24" className="apple-icon">
                          <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11z"/>
                        </svg>
                      </span>
                      <span className="store-text">
                        <small>Download on the</small>
                        App Store
                      </span>
                    </button>
                    <button className="store-btn">
                      <span className="store-icon">
                        <svg viewBox="0 0 24 24" className="play-icon">
                          <path d="M3,20.5V3.5C3,2.91 3.34,2.39 3.84,2.15L13.69,12L3.84,21.85C3.34,21.6 3,21.09 3,20.5M16.81,15.12L6.05,21.34L14.54,12.85L16.81,15.12M20.16,10.81C20.5,11.08 20.75,11.5 20.75,12C20.75,12.5 20.5,12.92 20.16,13.19L17.89,14.5L15.39,12L17.89,9.5L20.16,10.81M6.05,2.66L16.81,8.88L14.54,11.15L6.05,2.66Z"/>
                        </svg>
                      </span>
                      <span className="store-text">
                        <small>GET IT ON</small>
                        Google Play
                      </span>
                    </button>
                  </div>
                </div>
                
                <div className="app-preview" style={{ marginTop: '40px' }}>
                  <div className="phone-frame">
                    <div className="phone-screen">
                      <div className="carousel-container">
                        {phoneImages.map((image, index) => (
                          <img 
                            key={index}
                            src={image} 
                            alt={`App Screenshot ${index + 1}`} 
                            className={`app-screenshot ${index === currentIndex ? 'active' : ''}`}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="power-button"></div>
                    <div className="volume-up"></div>
                    <div className="volume-down"></div>
                  </div>
                </div>
              </section>

              <section id="about" className="about-section section-transition">
                <div className="about-container">
                  <div className="about-content">
                    <h2>{t.about.title}</h2>
                    <p>{t.about.description}</p>
                    <div className="about-stats">
                      <div ref={usersRef} className="stat-item">
                        <div className="stat-number">{formatNumber(userCount)}</div>
                        <div className="stat-label">Aktif Kullanıcı</div>
                      </div>
                      <div ref={shipmentsRef} className="stat-item">
                        <div className="stat-number">{formatNumber(shipmentCount)}</div>
                        <div className="stat-label">Tamamlanan Sevkiyat</div>
                      </div>
                      <div ref={citiesRef} className="stat-item">
                        <div className="stat-number">{formatNumber(cityCount)}</div>
                        <div className="stat-label">Şehir</div>
                      </div>
                    </div>
                    <div className="about-ad">
                      <img 
                        src={ad2Image} 
                        alt="moveout.app advertisement" 
                        className="ad-image"
                      />
                    </div>
                  </div>
                  <div className="about-image">
                    <img 
                      src={aboutImage} 
                      alt="moveout.app nakliye ve lojistik çözümleri" 
                      loading="lazy"
                    />
                  </div>
                </div>
              </section>

              <section id="features" className="features-showcase section-transition">
                <div className="section-container">
                  <div className="features-list">
                    {t.features.items.map((feature, index) => (
                      <div className="feature-row" key={index}>
                        <div className="feature-number">{feature.number}</div>
                        <div className="feature-content">
                          <h3>{feature.title}</h3>
                          <p>{feature.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>

              <section id="testimonials" className="vision-section section-transition">
                <div className="section-container">
                  <h2>{t.testimonials.title}</h2>
                  <div className="vision-grid">
                    {t.vision.items.map((item, index) => (
                      <div className="vision-item" key={index}>
                        <div className="vision-icon">{item.icon}</div>
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                      </div>
                    ))}
                  </div>
                  <div className="vision-footer">
                    <p>{t.vision.footer}</p>
                  </div>
                </div>
              </section>

              <section className="stats-divider">
                {/* This empty section will show the gradient line */}
              </section>

              <section id="faq" className="faq-section">
                <div className="section-container">
                  <h2 className="section-title">{t.faq.title}</h2>
                  <div className="faq-grid">
                    <Suspense fallback={<div>Loading...</div>}>
                      <FAQ questions={t.faq.items} lang={currentLang} />
                    </Suspense>
                  </div>
                </div>
              </section>

              <section className="newsletter-section">
                <Suspense fallback={<div>Loading...</div>}>
                  <Newsletter 
                    translations={t.newsletter} 
                    currentLang={currentLang}
                  />
                </Suspense>
              </section>
            </main>

            <footer className="App-footer">
              <div className="footer-content">
                <div className="footer-logo">
                  <img src={logo} alt="moveout.app" />
                  <video 
                    className="footer-video" 
                    autoPlay 
                    loop 
                    muted 
                    playsInline
                  >
                    <source src={footerVideo} type="video/mp4" />
                  </video>
                </div>
                <div className="footer-links">
                  <div className="footer-section">
                    <h4>{t.footer.company}</h4>
                    <ul>
                      <li><a href="#about">{t.nav.about}</a></li>
                      <li><a href="#features">{t.nav.features}</a></li>
                      <li><a href="#faq">{t.nav.faq}</a></li>
                    </ul>
                  </div>
                  <div className="footer-section">
                    <h4>{t.footer.contact}</h4>
                    <ul>
                      <li>
                        <a 
                          href="mailto:hello@moveout.app" 
                          className="email-link"
                        >
                          hello@moveout.app
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-section">
                    <h4>{t.footer.social}</h4>
                    <ul className="social-links">
                      <li>
                        <a 
                          href="https://www.instagram.com/moveout.app/" 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="social-link"
                        >
                          <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                          </svg>
                          Instagram
                        </a>
                      </li>
                      <li>
                        <a 
                          href="https://www.linkedin.com/company/moveout-app/" 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="social-link"
                        >
                          <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                          </svg>
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-bottom">
                  <p className="copyright">{t.footer.rights}</p>
                  <div className="legal-links">
                    <a href="/privacy">{t.footer.privacy}</a>
                    <a href="/terms">{t.footer.terms}</a>
                  </div>
                </div>
              </div>
            </footer>
          </>
        )}
        <Analytics />
      </div>
    </HelmetProvider>
  );
}

export default App;
