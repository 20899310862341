import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || 'https://xmcgqfgyvrwrsmfbqssw.supabase.co';
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhtY2dxZmd5dnJ3cnNtZmJxc3N3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjUzMDM4MDQsImV4cCI6MjA0MDg3OTgwNH0.Dvwwq53ZiFf0AKt_FbJyB1LX-jUSXJBkaRE10V0Pw8E';

if (!supabaseUrl || !supabaseAnonKey) {
  console.error('Supabase URL or Anon Key is missing');
}

// Ensure the URL has the correct protocol
const formattedUrl = supabaseUrl.startsWith('http') ? supabaseUrl : `https://${supabaseUrl}`;

export const supabase = createClient(formattedUrl, supabaseAnonKey); 