import { useState, useEffect, useCallback } from 'react';

const useRateLimit = (maxAttempts, timeWindow) => {
  const [attempts, setAttempts] = useState([]);
  const [canSubmit, setCanSubmit] = useState(true);

  useEffect(() => {
    // Clean up old attempts
    const now = Date.now();
    const validAttempts = attempts.filter(
      timestamp => now - timestamp < timeWindow
    );

    setAttempts(validAttempts);
    setCanSubmit(validAttempts.length < maxAttempts);

    // Set up cleanup interval
    const interval = setInterval(() => {
      setAttempts(prev => {
        const now = Date.now();
        const valid = prev.filter(
          timestamp => now - timestamp < timeWindow
        );
        return valid;
      });
    }, timeWindow);

    return () => clearInterval(interval);
  }, [attempts, maxAttempts, timeWindow]);

  const addAttempt = useCallback(() => {
    const now = Date.now();
    setAttempts(prev => [...prev, now]);
  }, []);

  const resetLimit = useCallback(() => {
    setAttempts([]);
    setCanSubmit(true);
  }, []);

  return {
    canSubmit,
    addAttempt,
    resetLimit,
    attemptsRemaining: Math.max(0, maxAttempts - attempts.length)
  };
};

export default useRateLimit; 