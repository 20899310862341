import DOMPurify from 'dompurify';

export const sanitizeInput = (value) => {
  if (typeof value !== 'string') return value;
  return DOMPurify.sanitize(value);
};

export const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const validatePhone = (phone) => {
  const phoneRegex = /^[0-9+\-\s]{10,15}$/;
  return phoneRegex.test(phone);
};

export const validateName = (name) => {
  const nameRegex = /^[\p{L}\s]{2,50}$/u;
  return nameRegex.test(name);
};

export const validateForm = (formData) => {
  const errors = {};

  if (!formData.name || !validateName(formData.name)) {
    errors.name = 'Please enter a valid name (2-50 characters, letters only)';
  }

  if (!formData.email || !validateEmail(formData.email)) {
    errors.email = 'Please enter a valid email address';
  }

  if (!formData.phone || !validatePhone(formData.phone)) {
    errors.phone = 'Please enter a valid phone number (10-15 digits)';
  }

  if (!['individual', 'business'].includes(formData.userType)) {
    errors.userType = 'Please select a valid user type';
  }

  if (!['sender', 'driver'].includes(formData.userRole)) {
    errors.userRole = 'Please select a valid user role';
  }

  if (!['email', 'sms', 'both'].includes(formData.notificationPreference)) {
    errors.notificationPreference = 'Please select a valid notification preference';
  }

  return errors;
}; 