import React, { useState, useEffect } from 'react';
import './Login.css';
import logo from '../moveouttr.png';
import SEO from './SEO';

const Login = ({ currentLang, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const translations = {
    tr: {
      subtitle: "Lojistik operasyonlarınızı yönetmeye başlayın",
      email: "E-posta",
      password: "Şifre",
      login: "Giriş Yap",
      forgotPassword: "Şifremi Unuttum",
      register: "Hesabınız yok mu? Ücretsiz Hesap Oluşturun",
      emailPlaceholder: "ornek@sirket.com",
      passwordPlaceholder: "Şifreniz",
      rememberMe: "Beni Hatırla",
      or: "veya",
      googleLogin: "Google ile devam et"
    },
    en: {
      subtitle: "Start managing your logistics operations",
      email: "Email",
      password: "Password",
      login: "Sign In",
      forgotPassword: "Forgot Password?",
      register: "Don't have an account? Create Free Account",
      emailPlaceholder: "example@company.com",
      passwordPlaceholder: "Your password",
      rememberMe: "Remember me",
      or: "or",
      googleLogin: "Continue with Google"
    }
  };

  const t = translations[currentLang];

  const rightSideContent = {
    tr: {
      title: "Hoş Geldiniz",
      description: "moveout.app ile sevkiyatlarınızı optimize edin, maliyetlerinizi düşürün ve sürdürülebilir bir gelecek için adım atın."
    },
    en: {
      title: "Welcome to Logistics World",
      description: "Optimize your shipments, reduce costs, and take a step towards a sustainable future with moveout.app"
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Simulate API call
    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      console.log('Login attempt with:', email, password);
    } catch (error) {
      console.error('Login error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 300); // Match this with animation duration
  };

  useEffect(() => {
    // Add escape key handler
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  return (
    <div className={`login-container ${isClosing ? 'slide-out' : ''}`}>
      <SEO 
        title={t.title}
        description="Login to your Moveout account to manage your shipments and logistics operations."
        keywords="login, account access, moveout login, logistics platform"
        url="/login"
        lang={currentLang}
        type="website"
      />
      <button onClick={handleClose} className="back-button">
        <img 
          src={require('../assets/back.png')} 
          alt="Back" 
          className="back-icon"
        />
      </button>

      <div className="login-content">
        <div className="login-left">
          <div className="login-header">
            <img src={logo} alt="Logo" className="login-logo" />
            <h1>{t.title}</h1>
            <p>{t.subtitle}</p>
          </div>

          <form onSubmit={handleSubmit} className="login-form">
            <div className="form-group" style={{"--index": 1}}>
              <label htmlFor="email">{t.email}</label>
              <div className="input-with-icon">
                <svg className="input-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path d="M20 4H4a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2zm-2 3l-6 5-6-5"/>
                </svg>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t.emailPlaceholder}
                  required
                />
              </div>
            </div>

            <div className="form-group" style={{"--index": 2}}>
              <label htmlFor="password">{t.password}</label>
              <div className="input-with-icon">
                <svg className="input-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <rect x="3" y="11" width="18" height="11" rx="2" ry="2"/>
                  <path d="M7 11V7a5 5 0 0110 0v4"/>
                </svg>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={t.passwordPlaceholder}
                  required
                />
              </div>
            </div>

            <div className="form-options" style={{"--index": 3}}>
              <label className="checkbox-container">
                <input type="checkbox" />
                <span className="checkmark"></span>
                <span className="checkbox-text">{t.rememberMe}</span>
              </label>
              <a href="#forgot-password" className="forgot-password">
                {t.forgotPassword}
              </a>
            </div>

            <button 
              type="submit" 
              className={`login-btn ${isLoading ? 'loading' : ''}`}
              disabled={isLoading}
              style={{"--index": 4}}
            >
              {isLoading ? (
                <div className="spinner"></div>
              ) : t.login}
            </button>

            <div className="social-divider" style={{"--index": 5}}>
              <span>{t.or}</span>
            </div>

            <div className="social-login" style={{"--index": 6}}>
              <button type="button" className="social-btn google">
                <svg className="social-icon" viewBox="0 0 24 24">
                  <path d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z"/>
                </svg>
                {t.googleLogin}
              </button>
            </div>
          </form>

          <div className="register-link">
            <a href="#register">{t.register}</a>
          </div>
        </div>

        <div className="login-right">
          <div className="login-image"></div>
          <div className="login-right-content">
            <h2>{rightSideContent[currentLang].title}</h2>
            <p>{rightSideContent[currentLang].description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login; 